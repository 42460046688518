import React from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";
import Copyright from "./Copyright";
import Listings from '../Pages/Listings';

export const ListingHome = () => {
    return (
        <>
            <Navbar />
            <Listings />
            {/* <Footer /> */}
            <Copyright />
        </>
    )
}
