import React from 'react'
import Navbar from "./Navbar";
import Firstsection from "../Pages/Firstsection";
import Footer from "./Footer";
import Copyright from "./Copyright";

export const Home = () => {
    return (
        <>
            <Navbar />
            <Firstsection />
            {/* <Footer /> */}
            <Copyright />
        </>
    )
}
