import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useWeb3React } from "@web3-react/core";
import { bsc, injected } from "../walletConnect/connector";
import { isMobile, isBrowser } from "react-device-detect";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let authState = useSelector((state) => state.auth);
  var { isConnected } = authState;

  const { activate, account, active } = useWeb3React();

  const connectWallet = async (providerType) => {
    if (providerType === "metaMask") {
      if (isMobile) {
        toast.error("Please install metaMask", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      await activate(injected);

      localStorage.setItem("isConnected", true);
      localStorage.setItem("providerType", "metaMask");
      // if (active) {
      dispatch({
        type: "WALLET_CONNECT",
        payload: {
          account: account,
          isConnected: localStorage.getItem("isConnected"),
          providerType: "metaMask",
        },
      });
      // }
    } else if (providerType === "trustWallet") {
      if (isBrowser) {
        toast.error("not detect dapp browser", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      await activate(injected);

      localStorage.setItem("isConnected", true);
      localStorage.setItem("providerType", "trustWallet");
      // if (active) {
      dispatch({
        type: "WALLET_CONNECT",
        payload: {
          account: account,
          isConnected: localStorage.getItem("isConnected"),
          providerType: "trustWallet",
        },
      });
    } else {
      if (isMobile || !window.BinanceChain) {
        toast.error("Please install Binance wallet", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      await activate(bsc);
      localStorage.setItem("isConnected", true);
      localStorage.setItem("providerType", "BSC");
      const iscon = localStorage.getItem("isConnected", true);
      console.log("iscon", iscon);
      // if (active) {
      dispatch({
        type: "WALLET_CONNECT",
        payload: {
          account: account,
          isConnected: true,
          providerType: "BSC",
        },
      });
      // }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("isConnected") && active) {
      window.location.replace("/");
    }
  }, [active, isConnected]);
  return (
    <div>
      <section className="home-page-first-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 pr-md-6 align-self-center text-center text-md-left content">
              <div className="sr-btn-wrap">
                <a className="sr-btn-2" onClick={() => navigate("/")}>
                  <i className="fa fa-arrow-left"></i>
                </a>
                <div className="go-back-title">Go Back</div>
              </div>
              <div className="connect-wallet">
                <h1>Please Connect Your Wallet</h1>
                <p>
                  Sign in with one of available wallet providers or
                  <br /> create a new wallet. <a href="#">
                    {" "}
                    What is a wallet?
                  </a>{" "}
                </p>

                <div className="sr-btn-wrap1">
                  <a
                    className="sr-btn"
                    onClick={() => connectWallet("trustWallet")}
                  >
                    Trust Wallet
                  </a>

                  <a
                    className="sr-btn"
                    onClick={() => connectWallet("metaMask")}
                  >
                    Metamask
                  </a>
                  <a className="sr-btn" onClick={() => connectWallet("BSC")}>
                    Binance
                  </a>
                </div>
                <h6>
                  We do not own your private keys and cannot access
                  <br /> your funds without your confirmation.
                </h6>
              </div>
            </div>
            <div className="col-12 col-md-6 p-0 image">
              <img src="images/login.png" className="fit-image" alt="" />
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Login;
