import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";

import STACK_ABI from "../abi/stack.json";
import WBNB from "../abi/WBNB.json";
import moment from "moment";

import { useSelector, useDispatch } from "react-redux";

function Firstsection() {
  var Router = "0xD71214DCF9199e87879f83815dB9bC3Af209BE7e";
  let authState = useSelector((state) => state.auth);
  var { isConnected } = authState;
  const { library, account } = useWeb3React();
  const web3Obj = library;

  const [dipositAmount, setDipositAmount] = useState("");
  const [timeperiod, setTimeperiod] = useState(0);
  const [timeperiodDate, setTimeperiodDate] = useState(
    moment().add(30, "days").format("DD/MM/YYYY h:mm A")
  );
  //   const [withdrawAmount, setWithdrawAmount] = useState("");
  const [balance, setBalance] = useState(0);

  const { deactivate } = useWeb3React();
  const dispatch = useDispatch();
  const disconnectWallet = () => {
    deactivate();
    dispatch({
      type: "WALLET_DISCONNECT",
      payload: {
        account: "",
        isConnected: false,
        providerType: "",
      },
    });
  };

  const [stackContractInfo, setStackContractInfo] = useState({
    totalStakers: 0,
    totalStakedToken: 0,
  });
  const [stakersInfo, setStakersInfo] = useState({
    totalStakedTokenUser: 0,
    totalUnstakedTokenUser: 0,
    totalClaimedRewardTokenUser: 0,
    currentStaked: 0,
    realtimeReward: 0,
    stakeCount: 0,
    alreadyExists: false,
  });
  const [stakersRecord, setStakersRecord] = useState([]);
  const tokenAddress = "0xFD9FacA15558eb243aC0012Cf7ACfBf480574668";

  //   const [toggleModal, setToggleModal] = useState(false);
  //   const [withdrawModal, setWithdrawModal] = useState(false);

  //   const [errorMsg, setErrorMsg] = useState("");
  //   const [isError, setIsError] = useState(false);

  const [isAllowance, setIsAllowance] = useState(false);
  //   const [isStaked, setIsStaked] = useState(true);
  const [loading, setLoadding] = useState(false);

  const notify = (isError, msg) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const checkAllowance = async (tokenAddress) => {
    try {
      setLoadding(true);

      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      var decimals = await tokenContract.methods.decimals().call();
      var getBalance = await tokenContract.methods.balanceOf(account).call();

      var pow = 10 ** decimals;
      var balanceInEth = getBalance / pow;
      setBalance(balanceInEth);
      var allowance = await tokenContract.methods
        .allowance(account, Router)
        .call();

      if (allowance <= 2) {
        setIsAllowance(true);
      }
      if (dipositAmount > 0) {
        var amount = dipositAmount * pow;
        if (allowance < amount) {
          setIsAllowance(true);
        }
      }
      setLoadding(false);
    } catch (err) {
      setLoadding(false);
    }
  };

  const approve = async (tokenAddress) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(WBNB, tokenAddress);

      var amountIn = 10 ** 69;
      amountIn = amountIn.toLocaleString("fullwide", { useGrouping: false });
      //   var amountIn = new web3Obj.utils.BigNumber("10").pow(69);

      await contract.methods
        .approve(Router, amountIn.toString())
        .send({ from: account })
        .then(() => {
          setIsAllowance(false);
          // checkAllowance(tokenAddress);
          setLoadding(false);
        });
    } catch (err) {
      console.log(err);
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const addMetamask = async () => {
    const tokenAddress = Router;
    const tokenSymbol = "MVRS";
    const tokenDecimals = 18;
    const tokenImage =
      "http://ec2-3-109-126-218.ap-south-1.compute.amazonaws.com/images/symbol.png";
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            // image: tokenImage, // A string url of the token logo
          },
        },
      });
      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stake = async (tokenAddress) => {
    if (isNaN(parseFloat(dipositAmount)) || parseFloat(dipositAmount) <= 0) {
      notify(true, "Error! please enter amount");
      return;
    }
    await checkAllowance(tokenAddress);
    setLoadding(true);
    try {
      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      const decimals = await tokenContract.methods.decimals().call();

      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);

      var pow = 10 ** decimals;
      var amountIn = dipositAmount * pow;
      // var amountInNew = `${new ethers.utils.BigNumber(amountIn.toString())}`;
      amountIn = amountIn.toLocaleString("fullwide", { useGrouping: false });

      await contract.methods
        .stake(amountIn.toString(), timeperiod.toString())
        .send({ from: account })
        .then((err) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          notify(false, "Staking process complete.");
        });
    } catch (err) {
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const unstake = async (index) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      await contract.methods
        .unstake(index.toString())
        .send({ from: account })
        .then((result) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          notify(false, "successfully unstake");
          //   withdrawModal();
        });
    } catch (err) {
      setLoadding(false);
      notify(true, "unstake fail");
    }
  };

  const harvest = async (index) => {
    setLoadding(true);
    try {
      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      await contract.methods
        .harvest(index.toString())
        .send({ from: account })
        .then((err) => {
          getStackerInfo(tokenAddress);
          setLoadding(false);
          checkAllowance(tokenAddress);
          notify(false, "Reward successfully harvested");
        });
    } catch (err) {
      console.log(err);
      setLoadding(false);
      notify(true, err.message);
    }
  };

  const getStackerInfo = async (tokenAddress) => {
    setLoadding(true);
    try {
      var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
      var decimals = await tokenContract.methods.decimals().call();
      var getBalance = await tokenContract.methods
        .balanceOf(account.toString())
        .call();
      var pow = 10 ** decimals;
      var balanceInEth = getBalance / pow;
      setBalance(balanceInEth);

      var contract = new web3Obj.eth.Contract(STACK_ABI, Router);
      var totalStakedToken = await contract.methods.totalStakedToken
        .call()
        .call();
      var totalStakers = await contract.methods.totalStakers.call().call();
      var realtimeReward = await contract.methods
        .realtimeReward(account)
        .call();
      var Stakers = await contract.methods.Stakers(account).call();

      var totalStakedTokenUser = Stakers.totalStakedTokenUser / pow;
      var totalUnstakedTokenUser = Stakers.totalUnstakedTokenUser / pow;
      var currentStaked = totalStakedTokenUser - totalUnstakedTokenUser;

      Stakers.totalStakedTokenUser = totalStakedTokenUser;
      Stakers.totalUnstakedTokenUser = totalUnstakedTokenUser;
      Stakers.currentStaked = currentStaked;
      Stakers.realtimeReward = realtimeReward / 10 ** 18;
      Stakers.totalClaimedRewardTokenUser =
        Stakers.totalClaimedRewardTokenUser / 10 ** 18;
      var stakersRecord = [];
      for (var i = 0; i < parseInt(Stakers.stakeCount); i++) {
        var stakersRecordData = await contract.methods
          .stakersRecord(account, i)
          .call();
        var realtimeRewardPerBlock = await contract.methods
          .realtimeRewardPerBlock(account, i.toString())
          .call();

        stakersRecordData.realtimeRewardPerBlock =
          realtimeRewardPerBlock[0] / 10 ** 18;

        stakersRecordData.unstaketime = moment
          .unix(stakersRecordData.unstaketime)
          .format("DD/MM/YYYY h:mm A");
        stakersRecordData.staketime = moment
          .unix(stakersRecordData.staketime)
          .format("DD/MM/YYYY h:mm A");
        stakersRecord.push(stakersRecordData);
      }
      setStakersInfo(Stakers);
      setStakersRecord(stakersRecord);
      setStackContractInfo({
        totalStakers: totalStakers,
        totalStakedToken: totalStakedToken / pow,
      });
      setLoadding(false);
      console.log(stakersRecord);
    } catch (err) {
      // console.log(err);
      setLoadding(false);
      setStakersInfo({
        totalStakedTokenUser: 0,
        totalUnstakedTokenUser: 0,
        totalClaimedRewardTokenUser: 0,
        currentStaked: 0,
        realtimeReward: 0,
        stakeCount: 0,
        alreadyExists: false,
      });
      setStackContractInfo({
        totalStakers: 0,
        totalStakedToken: 0,
      });
      setStakersRecord([]);
      setBalance(0);
    }
  };

  const setMaxWithdrawal = async () => {
    var tokenContract = new web3Obj.eth.Contract(WBNB, tokenAddress);
    var decimals = await tokenContract.methods.decimals().call();
    var getBalance = await tokenContract.methods
      .balanceOf(account.toString())
      .call();
    var pow = 10 ** decimals;
    var balanceInEth = getBalance / pow;
    setDipositAmount(balanceInEth.toFixed(5));
    // setWithdrawAmount(userInfo.staked);
  };

  useEffect(() => {
    if (isConnected) {
      checkAllowance(tokenAddress);
      getStackerInfo(tokenAddress);
    } else {
      getStackerInfo(tokenAddress);
    }
  }, [isConnected, account]);
  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
              <div className="title">
                <h1>
                  <span className="designerText">META MVRS STAKING</span>
                </h1>
                <div className="buttonWrapper">
                  {authState.account == null ? (
                    <ul>
                      {authState.isConnected ? (
                        <li>
                          {authState.account == null ? (
                            <Link to="/login" className="sr-btn">
                              <a data-toggle="modal" data-target="#myModal">
                                Connect Wallet
                              </a>{" "}
                            </Link>
                          ) : (
                            <div className="btn">
                              <div className="icon">
                                <svg
                                  viewBox="0 0 24 24"
                                  color="primary"
                                  width="24px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="sc-bdnxRM ACFFk"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="title">
                                {authState.account
                                  .slice(0, 2)
                                  .concat(`...${authState.account.slice(-4)}`)}
                              </div>
                              <svg
                                viewBox="0 0 24 24"
                                color="text"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                                className="sc-bdnxRM kDWlca"
                              >
                                <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                              </svg>
                              <div className="popover__content">
                                <div className="product-body">
                                  <button
                                    className="color"
                                    onClick={() => disconnectWallet()}
                                  >
                                    <div className="left">Disconnect</div>
                                    <div className="right">
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ fill: "#fd1ebe" }}
                                      >
                                        <path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path>
                                      </svg>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                      ) : (
                        <Link to="/login" className="sr-btn">
                          <li>Connect Wallet</li>
                        </Link>
                      )}
                    </ul>
                  ) : null}
                </div>
                {/* <div className="sr-btn-wrap">
                  <ul>
                    {authState.isConnected ? (
                      <li>
                        {authState.account == null ? (
                          <Link to="/login" className="sr-btn">
                            <a data-toggle="modal" data-target="#myModal">
                              Connect Wallet
                            </a>{" "}
                          </Link>
                        ) : (
                          <div className="btn">
                            <div className="icon">
                              <svg
                                viewBox="0 0 24 24"
                                color="primary"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                                className="sc-bdnxRM ACFFk"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
                                ></path>
                              </svg>
                            </div>
                            <div className="title">
                              {authState.account
                                .slice(0, 2)
                                .concat(`...${authState.account.slice(-4)}`)}
                            </div>
                            <svg
                              viewBox="0 0 24 24"
                              color="text"
                              width="24px"
                              xmlns="http://www.w3.org/2000/svg"
                              className="sc-bdnxRM kDWlca"
                            >
                              <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                            </svg>
                            <div className="popover__content">
                              <div className="product-body">
                                <button
                                  className="color"
                                  onClick={() => disconnectWallet()}
                                >
                                  <div className="left">Disconnect</div>
                                  <div className="right">
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ fill: "#fd1ebe" }}
                                    >
                                      <path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path>
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    ) : (
                      <Link to="/login" className="sr-btn">
                        <li>Connect Wallet</li>
                      </Link>
                    )}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="stack-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                    <div className="stack-box">
                      <div className="stack-header">
                        <div className="float">
                          <div
                            className="logo text-center"
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <img
                              src="images/symbolStake.png"
                              style={{ height: 50 }}
                              alt=""
                            />
                          </div>
                          <div className="title1">
                            <p>Powered by BSC</p>
                            <h3 className="lineSpace">MVRS STAKED EARN MVRS</h3>
                          </div>
                        </div>
                        <div className="right-side">
                          <div className="sr-btn-wrap">
                            <a href="/#" className="sr-btn">
                              MVRS
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="balance">
                        <div className="first-block">
                          <p>Bidding Balance</p>
                          <h3>{stackContractInfo.totalStakedToken} MVRS</h3>
                        </div>
                        <div className="second-block">
                          <p>Bidding Stakers</p>
                          <h3> {stackContractInfo.totalStakers}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                        <div className="stack-box-1">
                          <div className="stack">
                            <p>Current Stake</p>
                            <h3 style={{ color: "#fd1ebe" }}>
                              {parseFloat(stakersInfo.currentStaked).toFixed(5)}{" "}
                              MVRS
                            </h3>
                          </div>
                          <div className="stack">
                            <p>MVRS Earned</p>
                            <h3>
                              {parseFloat(stakersInfo.realtimeReward).toFixed(
                                10
                              )}{" "}
                              MVRS
                            </h3>
                          </div>
                          <div className="stack">
                            <p>Claimed Reward</p>
                            <h3>
                              {parseFloat(
                                stakersInfo.totalClaimedRewardTokenUser
                              ).toFixed(5)}{" "}
                              MVRS
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                        <div className="stack-box-1">
                          <div className="stack">
                            <p>Your MVRS Wallet Balance</p>
                            <h3 style={{ color: "#fd1ebe" }}>
                              {balance.toFixed(5)} MVRS
                            </h3>
                          </div>
                          <div className="stack">
                            <p>Total Staked</p>
                            <h3>
                              {" "}
                              {parseFloat(
                                stakersInfo.totalStakedTokenUser
                              ).toFixed(5)}{" "}
                              MVRS
                            </h3>
                          </div>
                          <div className="stack">
                            <p>Total Unstaked</p>
                            <h3>
                              {" "}
                              {parseFloat(
                                stakersInfo.totalUnstakedTokenUser
                              ).toFixed(5)}{" "}
                              MVRS
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                    <div className="stack-box">
                      <div className="stack-header">
                        <div className="float">
                          <div
                            className="logo text-center"
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <img
                              src="images/symbolStake.png"
                              style={{ height: 50 }}
                              alt=""
                            />
                          </div>
                          <div className="title1">
                            <h3 className="lineSpace">MVRS CALCULATOR</h3>
                            <p>STAKE MVRS TO EARN MVRS REWARD </p>
                          </div>
                        </div>
                        <div className="right-side">
                          <div className="sr-btn-wrap">
                            <a href="" className="sr-btn-2">
                              <i className="fa fa-refresh" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="text-box">
                        <form className="newsletter">
                          <input
                            placeholder="Insert Custom Amount"
                            type="text"
                            value={dipositAmount}
                            onChange={(e) => setDipositAmount(e.target.value)}
                          />
                          <button
                            type="button"
                            onClick={() => setMaxWithdrawal()}
                          >
                            max
                          </button>
                        </form>
                      </div>
                      <div className="btn-section">
                        <button
                          type="button"
                          onClick={async () => {
                            setTimeperiod(0);
                            setTimeperiodDate(
                              moment()
                                .add(7, "days")
                                .format("DD/MM/YYYY h:mm A")
                            );
                          }}
                          className={
                            timeperiod === 0
                              ? "button-word active"
                              : "button-word"
                          }
                        >
                          7 days
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            setTimeperiod(1);
                            setTimeperiodDate(
                              moment()
                                .add(30, "days")
                                .format("DD/MM/YYYY h:mm A")
                            );
                          }}
                          className={
                            timeperiod === 1
                              ? "button-word active"
                              : "button-word"
                          }
                        >
                          30 days
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            setTimeperiod(2);
                            setTimeperiodDate(
                              moment()
                                .add(90, "days")
                                .format("DD/MM/YYYY h:mm A")
                            );
                          }}
                          className={
                            timeperiod === 2
                              ? "button-word active"
                              : "button-word"
                          }
                        >
                          90 days
                        </button>
                        <button
                          type="button"
                          onClick={async () => {
                            setTimeperiod(3);
                            setTimeperiodDate(
                              moment()
                                .add(180, "days")
                                .format("DD/MM/YYYY h:mm A")
                            );
                          }}
                          className={
                            timeperiod === 3
                              ? "button-word active"
                              : "button-word"
                          }
                        >
                          180 days
                        </button>
                        <p>
                          {timeperiod === 0
                            ? "Return Calculated by 7% APY, For 7 days lock period. Locked until " +
                              timeperiodDate
                            : timeperiod === 1
                            ? "Return Calculated by 9% APY, For 30 days lock period. Locked until " +
                              timeperiodDate
                            : timeperiod === 2
                            ? "Return Calculated by 18% APY, For 90 days lock period. Locked until " +
                              timeperiodDate
                            : "Return Calculated by 45% APY, For 180 days lock period. Locked until " +
                              timeperiodDate}
                        </p>
                        {isConnected ? (
                          isAllowance ? (
                            <button
                              onClick={() => approve(tokenAddress)}
                              disabled={loading}
                              className="btn btn-danger-1"
                            >
                              {loading ? "Please wait, Loading.." : "Enable"}
                            </button>
                          ) : (
                            <button
                              onClick={() => stake(tokenAddress)}
                              disabled={loading}
                              className="btn btn-danger-1"
                            >
                              {loading
                                ? "Please wait, Loading.."
                                : "Stake my MVRS"}
                            </button>
                          )
                        ) : (
                          <a href="/login" className="btn btn-danger-1">
                            Connect Wallet
                          </a>
                        )}
                      </div>
                      <div className="tab-section">
                        <div className="tab-1">
                          <a
                            target="_blank"
                            href="https://bscscan.com/address/0xfd9faca15558eb243ac0012cf7acfbf480574668"
                          >
                            <button type="button"> token info</button>
                          </a>
                        </div>
                        <div className="tab-1">
                          <a target="_blank" href="https://mvrstoken.org">
                            <button type="button">Project Site</button>
                          </a>
                        </div>
                        <div className="tab-1">
                          <a
                            target="_blank"
                            href="https://bscscan.com/address/0xd71214dcf9199e87879f83815db9bc3af209be7e"
                          >
                            <button type="button">Contract</button>
                          </a>
                        </div>
                        <div className="tab-2">
                          <button onClick={addMetamask} type="button">
                            Add to Metamask
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
              <div
                className="rounded-tbl"
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th scope="col">Staked Amount</th>
                      <th scope="col">Stake Date</th>
                      <th scope="col">Unstake Date</th>
                      <th scope="col">Earn Reward</th>
                      <th scope="col">Harvest</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {stakersRecord.length > 0 ? (
                      stakersRecord.map((row, index) => {
                        return (
                          <tr>
                            <td style={{ color: "#25e72f" }}>
                              {parseFloat(row.amount) / 10 ** 18}
                            </td>
                            <td>{row.staketime}</td>
                            <td>{row.unstaketime}</td>
                            <td>MVRS</td>
                            <td>
                              {" "}
                              {parseFloat(row.realtimeRewardPerBlock).toFixed(
                                10
                              )}
                            </td>
                            <td>
                              {row.unstaked ? (
                                <button
                                  disabled={true}
                                  className="sr-btn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Unstaked
                                </button>
                              ) : (
                                <button
                                  disabled={loading}
                                  onClick={() => unstake(index)}
                                  className="sr-btn"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Unstake
                                </button>
                              )}
                            </td>
                            <td>
                              {row.withdrawan ? (
                                <button
                                  disabled={true}
                                  className="sr-btn-6"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Harvested
                                </button>
                              ) : (
                                <button
                                  disabled={loading}
                                  onClick={() => harvest(index)}
                                  className="sr-btn-6"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Harvest
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          You have no stake record yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Firstsection;
